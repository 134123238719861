@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/*
Some resets so headings can inherit weight from their parent containers.
See heading usage in this project for details.
Look into moving into the design system
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}

html {
  scroll-behavior: smooth;
}

.fieldBreakSm {
  flex-basis: 100%;
  height: 0;
}

select::-ms-expand {
  display: none;
}

.input:disabled {
  opacity: 0.7;
}

.form-container {
  min-width: 340px;
}

.active-link,
.tab-link:hover {
  border-bottom: 4px solid #f36768;
  padding-bottom: 4px;
  font-weight: bold;
  @apply text-just-black;
}

@media (min-width: 40em) {
  .fieldBreakSm {
    display: none;
  }
  .headerIconWidth {
    width: 78px;
  }
}

/* Large */
@media (min-width: 64em) {
  .InputField {
    width: 71.428571428571429%;
  }
  .Label {
    width: 85.714285714285714%;
  }
}

@media (min-width: 80em) {
  .confirmationLink {
    margin-left: 4.25rem;
    margin-right: 4.25rem;
  }
  .InputField {
    width: 50%;
  }
  .Label {
    width: 75%;
  }
}

/* Some specific styles that aren't reusable */

.fixedFieldS {
  width: 5rem;
}

.fixedFieldM {
  width: 10.75rem;
}

/* Sticky Progress Bar */
.ProgressBar {
  top: 3rem;
}

.checkbox--error input + label:before {
  border-color: #ff9c54;
}

.bg-seafoam-20 {
  background: rgba(47, 207, 160, 0.2);
}

.bg-tangerine-25 {
  background: rgba(255, 156, 84, 0.25);
}

.bg-tangerine-50 {
  background: rgba(255, 156, 84, 0.5);
}

.bg-tangerine-80 {
  background: rgba(255, 156, 84, 0.8);
}

.link:hover {
  font-weight: bold;
}

div[role="button"]:hover {
  outline: none;
}

@media (min-width: 64em) and (max-width: 80em) {
  .lg\:width-30 {
    width: 30%;
  }
}
